<template>
  <div id="bd">
    <div class="case-article">
      <div class="case-article-bg" style="margin-bottom: 13%">
        <div class="wp" id="news_info" v-html="detail.content"></div>
      </div>

      <div class="case-back">
        <a @click="$router.back()">返回新闻列表</a>
      </div>
    </div>
  </div>
</template>
<script>
import Http from '@/utils/http';

export default {
  data() {
    return {
      list: [1, 2, 3, 4, 5, 6, 7, 8],
      loading: false,
      finished: false,
      detail: {},
    };
  },
  created() {
    this.getDetail();
    // this.detail = JSON.parse(this.$route.query.news);
  },
  methods: {
    getDetail() {
      Http({
        method: 'get',
        url: 'https://rh.2hrh.com/Rhinfo/newsInfo/get',
        data: {
          id: this.$route.query.id,
        },
      }).then((res) => {
        this.detail = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep img {
  max-width: 100% !important;
}
#bd {
  padding-top: 90px;
}
.case-article {
  line-height: 30px;
  color: #666;
}
.case-article-bg {
  margin-bottom: 28%;
  margin-bottom: 5%\0 !important;
  background-position: top center\0 !important;
}
.case-article .case-logo {
  text-align: center;
  padding-top: 38px;
}

.case-article h1 {
  line-height: 40px;
  font-size: 18px;
  text-align: center;
}
.case-article .btn {
  text-align: center;
  padding: 10px 0;
  margin-bottom: 30px;
}
.case-article .btn a {
  display: inline-block;
  width: 120px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: #1071b5;
  color: #fff;
  font-size: 14px;
  margin: 0 12px;
  border-radius: 5px;
}

.case-article .computer-bg {
  position: relative;
  text-align: center;
  background: url(../../assets/front/computer.png) center top no-repeat;
  transform: translateY(45.9%);
  -webkit-transform: translateY(45.9%);
  -moz-transform: translateY(45.9%);
  width: 70%;
  margin: 0 auto;
  margin: -32% auto 0 auto;
  -webkit-background-size: 100%;
  background-size: 89%;
  padding-top: 70%;
  margin: 0 auto\0;
  padding-top: 50%\0;
}
.case-article .computer-bg img {
  position: absolute;
  left: 8.5%;
  top: 4%;
  width: 83%;
}
.case-article .mobile-bg {
  position: relative;
  text-align: center;
  background: url(../../assets/front/mobilePhone.png) center top no-repeat;
  transform: translateY(45.9%);
  -webkit-transform: translateY(45.9%);
  -moz-transform: translateY(45.9%);
  width: 70%;
  margin: 0 auto;
  margin: -8% auto 0 auto;
  padding-top: 70%;
  margin: 0 auto\0;
  padding-top: 50%\0;
}
.case-article .mobile-bg img {
  position: absolute;
  left: 32.5%;
  top: 12%;
  width: 34%;
}
.case-article .case-back {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 90px;
}
.case-article .case-back a {
  display: inline-block;
  width: 178px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  border: 1px solid #63637f;
  color: #002;
  font-size: 18px;
  border-radius: 5px;
}
.case-article .case-back a:hover {
  background-color: #1071b5;
  color: #fff;
  border-color: #1071b5;
}
.article-prev,
.article-next {
  position: absolute;
  top: 50%;
  background: url(../../assets/front/article_prev.png) no-repeat;
  width: 36px;
  height: 66px;
  margin-top: -33px;
  left: 0;
  z-index: 2;
}
.article-next {
  background-image: url(../../assets/front/article_next.png);
  left: auto;
  right: 0;
}
</style>
